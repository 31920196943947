import smoothscroll from 'smoothscroll-polyfill'
import { isMobile } from './utilities/device-util'

smoothscroll.polyfill();

(()=> {
	if (isMobile)
		import(/* webpackChunkName: "app.mobile" */ './mobile')
	else
		import(/* webpackChunkName: "app.desktop" */ './desktop')
})()